<template>
  <v-dialog v-model="viewer" max-width="1200">
    <v-card elevation="5" class="" v-if="viewer">
      <v-sheet
          color="primary"
          :dark="theme.dark"
      >
        <v-card-title>
          {{ bulletin.title_es }}
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn small
                     v-bind="attrs"
                     v-on="on">
                <v-icon left>mdi-sync-alert</v-icon>
                {{ TEXT('send_suggestion') }}
              </v-btn>
            </template>

            <v-form @submit.prevent="submitFeedback">
              <v-card>
                <v-card-title class="text-h5">
                  <v-icon left>mdi-sync-alert</v-icon>
                  {{ TEXT('send_suggestion') }}
                </v-card-title>

                <v-card-text>
                  <v-text-field
                      v-model="comment"
                      :label="TEXT('suggestion')"
                      counter
                      autofocus
                      maxlength="255"
                  ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                      type="submit"
                  >
                    {{ TEXT('thank_you') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>


          </v-dialog>
        </v-card-title>
        <v-card-subtitle>
          {{ (!bulletin.codes?.length) ? 'No DTCs' : bulletin.codes.join(', ') }}
        </v-card-subtitle>
      </v-sheet>
      <v-card-text class="mt-5">
        <h2 class="mt-3">{{ TEXT('issue') }}</h2>
        <BulletinContent v-for="(content, index) in bulletin.contents.trouble"
                         :content="content"
                         language="es"
                         :key="'trouble'+index"></BulletinContent>
        <h2 class="mt-3">{{ TEXT('cause') }}</h2>
        <BulletinContent v-for="(content, index) in bulletin.contents.reason"
                         :content="content"
                         language="es"
                         :key="'reason'+index"></BulletinContent>
        <h2 class="mt-3">{{ TEXT('solution') }}</h2>
        <BulletinContent v-for="(content, index) in bulletin.contents.solution"
                         :content="content"
                         language="es"
                         :key="'solution'+index"></BulletinContent>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import BulletinContent from "@/components/bulletins/BulletinContent.vue";

export default {
  name: "NextViewer",
  components: {BulletinContent},
  data() {
    return {
      viewer: false,
      loading: true,
      bulletin: null,
      dialog: null,
      comment: ''
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
    ...mapState([
      'language',
      'theme'
    ]),
  },
  methods: {
    show(bulletin) {
      this.bulletin = bulletin
      this.viewer = true
    },
  }
}
</script>

<style scoped>
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>