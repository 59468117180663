<template>
  <v-dialog v-model="viewer" max-width="1200">
    <v-card elevation="5" class="" v-if="viewer">
      <v-sheet
          color="primary"
          :dark="theme.dark"
      >
        <v-card-title>
          {{ bulletin.title }}
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn small
                     v-bind="attrs"
                     v-on="on">
                <v-icon left>mdi-sync-alert</v-icon>
                {{ TEXT('send_suggestion') }}
              </v-btn>
            </template>

            <v-form @submit.prevent="submitFeedback">
              <v-card>
                <v-card-title class="text-h5">
                  <v-icon left>mdi-sync-alert</v-icon>
                  {{ TEXT('send_suggestion') }}
                </v-card-title>

                <v-card-text>
                  <v-text-field
                      v-model="comment"
                      :label="TEXT('suggestion')"
                      counter
                      autofocus
                      maxlength="255"
                  ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                      type="submit"
                  >
                    {{ TEXT('thank_you') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>


          </v-dialog>
        </v-card-title>
        <v-card-subtitle v-text="bulletin.tags"></v-card-subtitle>
      </v-sheet>
      <v-card-text class="mt-5">
        <h5>{{ TEXT('issue') }}</h5>
        <p v-html="bulletin.trouble"></p>
        <h5>{{ TEXT('cause') }}</h5>
        <p v-html="bulletin.reason"></p>
        <h5>{{ TEXT('solution') }}</h5>
        <p v-html="bulletin.solution"></p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "LegacyViewer",
  data() {
    return {
      viewer: false,
      loading: true,
      bulletin: null,
      dialog: null,
      comment: ''
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
    ...mapState([
      'language',
      'theme'
    ]),
  },
  methods: {
    show(bulletin) {
      this.bulletin = bulletin
      this.viewer = true
    },
  }
}
</script>

<style scoped>
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>