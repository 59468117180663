<template>
  <v-navigation-drawer
      app
      v-model="menu"
      temporary
      right
      :dark="theme.dark"
  >

    <v-list-item class="primary--text">
      <v-list-item-title>
        <language-selector></language-selector>
      </v-list-item-title>
    </v-list-item>
    <v-list-item class="primary--text">
      <v-list-item-title>{{ userName }}</v-list-item-title>
      <v-btn
          icon
          to="/"
          color="primary"
      >
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider class="mt-0"></v-divider>

    <v-list class="pl-2">
      <v-list-item to="/bulletins">
        <v-list-item-title><v-icon class="mr-2">mdi-book</v-icon> {{TEXT('bulletins')}}</v-list-item-title>
      </v-list-item>

      <template v-if="language!='it'">
        <v-list-item to="/academy/system">
          <v-list-item-title><v-icon class="mr-2">mdi-car-brake-abs</v-icon> {{TEXT('system_courses')}}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/academy/vehicle">
          <v-list-item-title><v-icon class="mr-2">mdi-car</v-icon> {{TEXT('vehicle_courses')}}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/academy/fr" v-if="language=='fr'">
          <v-list-item-title><a class="fi fi-fr mr-4"></a>Cours en français</v-list-item-title>
        </v-list-item>
        <v-list-item to="/academy/pt" v-if="language=='pt'">
          <v-list-item-title><a class="fi fi-pt mr-4"></a>Cursos em português</v-list-item-title>
        </v-list-item>
      </template>

      <v-list-item to="/procedure">
        <v-list-item-title><v-icon class="mr-2">mdi-speedometer</v-icon> {{TEXT('processes')}}</v-list-item-title>
      </v-list-item>
      <v-list-item to="/IA">
        <v-list-item-title><v-icon class="mr-2">mdi-robot</v-icon> IA</v-list-item-title>
      </v-list-item>
      <v-list-item to="/partners">
        <v-list-item-title><v-icon class="mr-2">mdi-star-shooting</v-icon> {{TEXT('our_brands')}}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import LanguageSelector from "@/components/LanguageSelector.vue";

export default {
  name: "MenuDrawer",
  components: {LanguageSelector},
  computed: {
    userName() {
      return sessionStorage.workshop;
    },
    ...mapGetters(['TEXT']),
    ...mapState([
      'partner',
      'theme',
      'language'
    ]),
    menu: {
      get() {
        return this.$store.state.menu
      },
      set(value) {
        this.$store.commit('SET_MENU', value)
      }
    }
  }
}
</script>

<style scoped>
a{
  text-decoration: none !important;
}
</style>