<script>
import {mapGetters, mapState} from "vuex";
import api from "@/api";
import HaynesViewer from "@/components/bulletins/HaynesViewer.vue";
import LegacyViewer from "@/components/bulletins/LegacyViewer.vue";
import NextViewer from "@/components/bulletins/NextViewer.vue";

export default {
  name: "BulletinMulti",
  components: {NextViewer, LegacyViewer, HaynesViewer},
  data() {
    return {
      LEGACY: null,
      NEXT: null,
      TSB: null,
      RECALL: null,
      CASE: null,
      tab: 0,
      loading: true,
      wheelDebounce: false,
      filter: ''
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
    ...mapState([
      'vehicle',
      'category',
      'language'
    ]),
    filteredLEGACY() {
      if (!this.LEGACY) { return [] }
      let filter = this.filter
      return this.LEGACY.filter(item => {
        let match1 = item.title.toLowerCase().includes(filter.toLowerCase());
        let match2 = item.tags.toLowerCase().includes(filter.toLowerCase());
        return match1 || match2
      })
    },
    filteredNEXT() {
      if (!this.NEXT) { return [] }
      let filter = this.filter
      return this.NEXT.filter(item => {
        return item.value['title_es'].toLowerCase().includes(filter.toLowerCase());
      })
    },
    filteredRECALL() {
      if (!this.RECALL) { return [] }
      let filter = this.filter
      return this.RECALL.SISTEMAS.filter(item => {
        return item['Descripcion'].toLowerCase().includes(filter.toLowerCase());
      })
    },
    filteredTSB() {
      if (!this.TSB) { return [] }
      let filter = this.filter
      return this.TSB.SISTEMAS.filter(item => {
        return item['Descripcion'].toLowerCase().includes(filter.toLowerCase());
      })
    },
    filteredCASE() {
      if (!this.CASE) { return [] }
      let filter = this.filter
      return this.CASE.SISTEMAS.filter(item => {
        return item['Descripcion'].toLowerCase().includes(filter.toLowerCase());
      })
    },

  },
  methods: {
    listHaynes() {
      api.GET_HAYNES_LIST(this.vehicle.extcode, this.language)
          .then(response => {
            console.log(response.data)
            let pack = response.data.SMART_PACK
            this.TSB = pack.filter(item => {
              return item['Categoria'] === 'TSB'
            })[0]
            this.RECALL = pack.filter(item => {
              return item['Categoria'] === 'RECALL'
            })[0]
            this.CASE = pack.filter(item => {
              return item['Categoria'] === 'CASE'
            })[0]
          })
    },
    async listBulletins() {
      try {
        const [legacyResponse, nextResponse] = await Promise.all([
          api.GET_BULLETINS(this.vehicle.vehicleID),
          api.GET_BULLETIN_LIST(this.vehicle.vehicleID)
        ]);

        if (!legacyResponse.data.auth) {
          // this.logout();
          return;
        }

        this.LEGACY = legacyResponse.data.list;
        this.NEXT = nextResponse.data.rows;
        this.loading = false
      } catch (error) {
        console.error('Error fetching bulletins:', error);
      }
    },
    select_haynes(type, id) {
      this.$refs.haynesviewer.show(type, id)
    },
    select_legacy(id) {
      let bulletin = this.filteredLEGACY[id]
      this.$refs.legacyviewer.show(bulletin)
    },
    select_next(id) {
      let bulletin = this.filteredNEXT[id].value
      this.$refs.nextviewer.show(bulletin)
    },
    wheelFun(event) {
      if (event.deltaY < 0) {
        this.tab--
      } else {
        this.tab++
      }
    }
  },
  created() {
    if (!this.vehicle.vehicleID) {
      this.$store.commit('CHANGE_TSB_VIEW', 0);
    } else {
      api.SELECT_VEHICLE(this.vehicle.makerID, this.vehicle.modelID, this.vehicle.vehicleID);
      this.listHaynes()
      this.listBulletins()
    }
  }
}
</script>

<template>
  <div class="container-fluid">
    <div class="d-inline-flex">
      <img :src="'https://assets.infotechnic.es/logos/'+vehicle.maker.toLowerCase()+'.png'" alt=""
           class="img-fluid mr-2"
           style="height: 50px">
      <h1>{{ vehicle.maker }} {{ vehicle.model }} ({{ vehicle.motor }})</h1>
    </div>
    <div @wheel.prevent="wheelFun">
      <v-tabs v-model="tab">
        <v-tab v-if="language === 'es'">Resolved</v-tab>
        <v-tab :disabled="!TSB">SmartFIX</v-tab>
        <v-tab :disabled="!RECALL">Recalls</v-tab>
        <v-tab :disabled="!CASE">SmartCASE</v-tab>
      </v-tabs>
    </div>


    <v-tabs-items v-model="tab">

      <v-tab-item v-if="language === 'es'">
        <v-card dark class="mt-3" :loading="loading">
          <v-card-title class="pb-0">
            <v-row>
              <v-col cols="2" class="hidden-sm-and-down">
                <img class="mx-0 mt-2" src="@/assets/logo-blanco-naranja-horizontal.png" alt="" height="40">
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="4">
                <v-text-field
                    autofocus
                    v-model="filter"
                    prepend-inner-icon="mdi-magnify"></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-list dark v-if="NEXT || LEGACY">
            <v-list-item
                link
                v-for="(item, index) in filteredNEXT"
                :key="item.id"
                @click="select_next(index)"
            >
              {{ item.value['title_es'] }}
              <v-icon right color="primary">mdi-new-box</v-icon>
            </v-list-item>
            <v-list-item
                link
                v-for="(item, index) in filteredLEGACY"
                :key="item.bulletin"
                @click="select_legacy(index)"
            >
              {{ item.title }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card dark class="mt-3" :loading="loading">
          <v-card-title class="white--text text-h5" style="background-color: #439639">
            <v-row>
              <v-col cols="2" class="hidden-sm-and-down">
                <img class="mx-0 mt-2" src="@/assets/haynes.gif" alt="" height="50">
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="4">
                <v-text-field
                    autofocus
                    v-model="filter"
                    prepend-inner-icon="mdi-magnify"></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-list dark v-if="filteredTSB">
            <v-list-item
                link
                v-for="item in filteredTSB"
                :key="item.Id"
                @click="select_haynes('TSB', item.Id)"
            >
              {{ item.Descripcion }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card dark class="mt-3" :loading="loading">
          <v-card-title class="white--text text-h5" style="background-color: #439639">
            <v-row>
              <v-col cols="2" class="hidden-sm-and-down">
                <img class="mx-0 mt-2" src="@/assets/haynes.gif" alt="" height="50">
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="4">
                <v-text-field
                    autofocus
                    v-model="filter"
                    prepend-inner-icon="mdi-magnify"></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-list dark v-if="filteredRECALL">
            <v-list-item
                link
                v-for="item in filteredRECALL"
                :key="item.Id"
                @click="select_haynes('RECALL', item.Id)"
            >
              {{ item.Descripcion }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card dark class="mt-3" :loading="loading">
          <v-card-title class="white--text text-h5" style="background-color: #439639">
            <v-row>
              <v-col cols="2" class="hidden-sm-and-down">
                <img class="mx-0 mt-2" src="@/assets/haynes.gif" alt="" height="50">
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="4">
                <v-text-field
                    autofocus
                    v-model="filter"
                    prepend-inner-icon="mdi-magnify"></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-list dark v-if="filteredCASE">
            <v-list-item
                link
                v-for="item in filteredCASE"
                :key="item.Id"
                @click="select_haynes('CASE', item.Id)"
            >
              {{ item.Descripcion }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <HaynesViewer ref="haynesviewer"></HaynesViewer>
    <LegacyViewer ref="legacyviewer"></LegacyViewer>
    <NextViewer ref="nextviewer"></NextViewer>
  </div>
</template>

<style lang="scss" scoped>
@import '../../theme';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
</style>