<template>
  <div class="container-fluid">

    <!--BREADCRUMB-->
    <nav aria-label="breadcrumb" v-if="tsb_view !== 0">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a @click="selectView(0)" class="primary--text"><v-icon>mdi-home</v-icon></a>
        </li>
        <li class="breadcrumb-item" v-if="!!vehicle.maker">
          <a @click="selectView(1)" class="primary--text">{{ vehicle.maker }}</a>
        </li>
        <li class="breadcrumb-item" v-if="!!vehicle.modelID">
          <a @click="selectView(2)" class="primary--text">{{ vehicle.model }}</a>
        </li>
        <li class="breadcrumb-item" v-if="!!vehicle.vehicleID">
          <a @click="selectView(3)" class="primary--text">{{ vehicle.motor }}</a>
        </li>
        <li class="breadcrumb-item" v-if="!!category"><b>{{ categoryName }}</b></li>
      </ol>
    </nav>

    <!-- TODO SELECTED VEHICLE DISPLAY HERE -->

    <maker-selector v-if="tsb_view === 0"></maker-selector>
    <model-selector v-if="tsb_view === 1"></model-selector>
    <vehicle-selector v-if="tsb_view === 2"></vehicle-selector>
    <bulletin-multi v-if="tsb_view === 3"></bulletin-multi>
    <!--    <category-selector v-if="tsb_view === 3"></category-selector>-->
    <!--    <bulletin-selector v-if="tsb_view === 4"></bulletin-selector>-->
    <haynes-selector v-if="tsb_view === 5" ref="haynes"></haynes-selector>

  </div>
</template>

<script>
import {mapState} from "vuex";
import MakerSelector from "@/components/bulletins/MakerSelector.vue";
import ModelSelector from "@/components/bulletins/ModelSelector.vue";
import VehicleSelector from "@/components/bulletins/VehicleSelector.vue";
// import CategorySelector from "@/components/bulletins/CategorySelector.vue";
// import BulletinSelector from "@/components/bulletins/BulletinSelector.vue";
import HaynesSelector from "@/components/bulletins/HaynesSelector.vue";
import BulletinMulti from "@/components/bulletins/BulletinMulti.vue";

export default {
  name: "bulletins-view",
  components: {
    BulletinMulti,
    HaynesSelector, VehicleSelector, ModelSelector, MakerSelector},
  data() {
    return {}
  },
  computed: {
    ...mapState([
      'vehicle',
      'tsb_view',
      'category',
      'categoryName'
    ]),
  },
  methods: {
    selectView(view) {
      this.$store.commit('CHANGE_TSB_VIEW', view)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../theme';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
</style>

