<script>
export default {
  name: "BulletinContent",
  props: ['content', 'language'],
}
</script>

<template>
  <div>
    <div v-if="!content['head_'+language]?.length && !content['text_'+language]?.length && !content.image?.length">
      <v-alert color="red" variant="outlined"> Contenido Nuevo (Haz click para editar)</v-alert>
    </div>
    <h5 v-if="content['head_'+language]">{{ content['head_' + language] }}</h5>
    <p v-if="content['text_'+language]">{{ content['text_' + language] }}</p>
    <v-row class="mt-1" v-if="content.image">
      <v-spacer></v-spacer>
      <v-col cols="auto" class="text-center">
        <img class="v-responsive" v-if='content.image.length' :src="content.image"/>
      </v-col>
      <v-col cols="auto" v-if="content['legend_'+language]">
        <v-sheet
            class="pa-4 mx-auto legend-sheet"
            max-width="400"
            style="white-space: pre-wrap"
            border="primary"
        >{{ content['legend_' + language] }}
        </v-sheet>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>

<style scoped>

</style>