<template>
  <v-container class="text-center">
    <h1 class="display-3 font-weight-bold">{{ TEXT('our_brands').toUpperCase() }}</h1>
    <hr>
    <v-row>
      <v-col
          v-for="item in brands"
          :key="item"
          class="d-flex child-flex"
          cols="6"
          lg="3"
      >
        <v-img
            :src="'https://assets.infotechnic.es/brands/'+item"
            contain
            class="ma-3"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "video-view",
  data() {
    return {
      brands: [
        'autel.svg',
        'brembo-textar.png',
        'continental.svg',
        'haynes.png',
        'shell.png',
        'kyb.png',
        'mahle.png',
        'meatdoria.png',
        'niterra.png',
        'sntools.png',
        'tudor.webp',
      ]
    }
  },
  computed: {
    ...mapGetters([
        'TEXT'
    ])
  },
}
</script>
