export default {
    next: {
        primary: '#ed6b00',
        dark: true,
        powered: true,
        text: 'white'
    },
    andel: {
        primary: '#e7521f',
        dark: false,
        powered: true,
        text: 'white'
    },
    bestdrive: {
        primary: '#eca02d',
        dark: true,
        powered: true,
        text: 'white'
    },
    cga: {
        primary: '#004f84',
        dark: false,
        powered: true,
        text: 'white'
    },
    optimacar: {
        primary: '#ffc000',
        dark: true,
        powered: false,
        text: 'white'
    },
    voltaf: {
        primary: '#fd010b',
        dark: false,
        powered: false,
        text: 'white'
    },
    napa: {
        primary: '#ffc72b',
        dark: true,
        powered: true,
        text: 'text--darken-4 blue'
    },
    atehi: {
        primary: '#f91800',
        dark: true,
        powered: true,
        text: 'white'
    }
}